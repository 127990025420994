class HeaderManager
{
  constructor()
  {
  }

  start()
  {
    this.container = document.querySelector('.header');
    this.title = document.querySelector('.header__navbar-title');
  }

  change_title(title)
  {
    this.title.innerHTML = title || '';
  }

  update()
  {
  }

  on_resize()
  {
  }
}

export default new HeaderManager();
