import { ResourceContainer } from 'ohzi-core';
// import { EasingFunctions } from 'ohzi-core';
// import { Time } from 'ohzi-core';
import { ModelUtilities } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { SceneManager } from 'ohzi-core';
// import { CameraManager } from 'ohzi-core';
// import { Components } from 'ohzi-core';

import ScreenSpaceLine from './ScreenSpaceLine';
import ScreenSpaceSquare from './ScreenSpaceSquare';
import CameraTargetTrackingState from '/js/components/CameraController/states/CameraTargetTrackingState';
import SpeedShapeCameraController from '/js/components/SpeedShapeCameraController';

export default class MainScene extends THREE.Object3D
{
  constructor(camera_controller)
  {
    super();

    this.camera_controller = camera_controller;

    // Debug.draw_axis();
    // SceneManager.current.add(new Components.Grid());

    // this.camera_tracker = new CameraTargetTrackingState(camera_controller);
    // camera_controller.set_state(this.camera_tracker);
    let object_points = ResourceContainer.get('path_points').scene.getObjectByName('path_points').children;

    object_points.sort((a, b) =>
    {
      let a_number = parseFloat(a.name.split('_')[1]);
      let b_number = parseFloat(b.name.split('_')[1]);
      return a_number - b_number;
    });

    let curves = [];

    for (let i = 0; i < object_points.length - 1; i++)
    {
      curves.push([
        object_points[i].position.clone(),
        object_points[i + 1].position.clone()
      ]);
    }

    this.lines = [];
    this.squares = [];
    this.zooms = [];

    let shape_scale = Configuration.is_mobile ? 30 : 30;
    let curve_scale = 10 * shape_scale;
    let far_out_zoom = Configuration.is_mobile ? 17 * shape_scale : 10 * shape_scale;
    let line_thickness = 4;

    let square_selected_size = 20;
    let square_unselected_size = 13;

    for (let i = 0; i < curves.length; i++)
    {
      this.scale_point_array(curves[i], curve_scale * 100);

      let line = new ScreenSpaceLine('#3F91A5', '#E6E6E6', line_thickness);
      line.renderOrder = 500;
      line.setup(curves[i]);
      this.lines.push(line);
      this.add(line);

      this.add_square(curves[i][0], 1 * shape_scale);
    }

    this.zooms[0] = 1 * shape_scale;

    let last_curve = curves[curves.length - 1];
    let last_point = last_curve[last_curve.length - 1];
    this.add_square(last_point, 1 * shape_scale);

    let speed_shapes = ResourceContainer.get('speed_shapes').scene;
    speed_shapes.scale.multiplyScalar(shape_scale);
    this.speed_shape_material = new THREE.MeshBasicMaterial({ color: '#F0F0F0', transparent: true });

    ModelUtilities.assign_material(speed_shapes, this.speed_shape_material);
    SceneManager.current.add(speed_shapes);

    this.camera_tracker = new CameraTargetTrackingState(camera_controller);
    camera_controller.set_state(this.camera_tracker);

    this.speed_camera_controller = new SpeedShapeCameraController({
      camera_tracker: this.camera_tracker,
      square_array: this.squares,
      lines_array: this.lines,
      zoom_array: this.zooms,
      default_size: square_unselected_size,
      selected_size: square_selected_size,
      zoom_far_out: far_out_zoom,
      default_thickness: line_thickness
    });
    // this.speed_camera_controller.set_index(-1);

    this.far_out_zoom = far_out_zoom;
  }

  scale_point_array(array, scale = 1)
  {
    for (let i = 0; i < array.length; i++)
    {
      array[i].multiplyScalar(scale);
    }
  }

  update()
  {
    for (let i = 0; i < this.lines.length; i++)
    {
      this.lines[i].update();
    }
    for (let i = 0; i < this.squares.length; i++)
    {
      this.squares[i].update();
    }

    this.speed_shape_material.opacity = 1 - THREE.Math.clamp((this.far_out_zoom - this.camera_controller.reference_zoom) / 20, 0, 1);
  }

  add_square(pos, default_zoom = 3)
  {
    let square = new ScreenSpaceSquare('#50ABAE', 30);
    square.position.copy(pos);
    square.renderOrder = 501;
    this.squares.push(square);
    this.add(square);
    this.zooms.push(default_zoom);
  }
}
