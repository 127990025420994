import { BaseShaderMaterial } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import vert from '/shaders/line_ss/line_ss.vert';
import frag from '/shaders/line_ss/line_ss.frag';
// import { Graphics } from 'ohzi-core';
import { Time } from 'ohzi-core';

export default class ScreenSpaceLineMaterial extends BaseShaderMaterial
{
  constructor(color = '#FF0000', unfilled_color = '#000000', thickness = 10)
  {
    super(vert, frag, {
      _Thickness: { value: thickness * Screen.dpr },
      _Length: { value: 0 },
      _ScreenSize: { value: new THREE.Vector2(1, 1) },
      _Color: { value: new THREE.Color(color) },
      _UnfilledColor: { value: new THREE.Color(unfilled_color) },
      _Fill: { value: 0.5 }
    });
    this.depthWrite = false;
    this.depthTest = false;

    this.target_fill = 0;

    this.t = -0.5;
  }

  update()
  {
    this.uniforms._ScreenSize.value.x = Screen.render_width;
    this.uniforms._ScreenSize.value.y = Screen.render_height;

    // this.uniforms._Fill.value += this.target_fill * Time.delta_time;
    // this.uniforms._Fill.value = THREE.Math.clamp(this.uniforms._Fill.value, 0, 1);

    this.t += this.target_fill * Time.delta_time;
    this.t = THREE.Math.clamp(this.t, -0.5, 1);
    this.uniforms._Fill.value = THREE.Math.clamp(this.t, 0, 1);
  }

  set_color(col)
  {
    this.uniforms._Color.value.set(col);
  }

  set_thickness(val)
  {
    this.uniforms._Thickness.value = val * Screen.dpr;
  }

  set_fill(val = 0)
  {
    this.target_fill = val;
  }
}
