import Sections from '../../components/Sections';

import sections_data from '../../../assets/data/why.json';
import SubsectionView from '../common/SubsectionView';

export default class WhyView extends SubsectionView
{
  constructor(view_data)
  {
    super(Sections.sections.WHY, view_data.title);

    // this.carousel_container = $('.why__carousel-select');
    // this.carousel_cubes = $('.why__carousel-select-box');
    // this.carousel_select = undefined;

    // this.carousel_select = new WhyCarouselSelect(this.carousel_cubes,
    //   this.carousel_container,
    //   this);

    // this.why_images = $('.why__image');
  }

  start()
  {
    super.start(document.querySelector('.why'), sections_data);
  }

  show()
  {
    super.show();

    // this.carousel_select.init();
    // this.carousel_select.on_resize();
  }

  update()
  {
    super.update();
    // this.carousel_select.update();
  }

  on_resize()
  {
    // this.carousel_select.on_resize();
  }

  // on_element_selected(element_id)
  // {
  //   if ($(`.why__image.${element_id}`).hasClass('hidden'))
  //   {
  //     this.why_images.addClass('hidden');
  //     $(`.why__image.${element_id}`).removeClass('hidden');
  //   }
  // }
}
