import HTMLManager from './HTMLManager';

class SideMenuManager
{
  constructor()
  {
  }

  start()
  {
    this.container = document.querySelector('.side-menu');

    this.buttons = document.querySelectorAll('.side-menu__item');
  }

  go_to(button, section_name)
  {
    this.unactive_buttons();

    button.classList.add('active');

    HTMLManager.go_to(section_name);
  }

  update()
  {
  }

  on_resize()
  {
  }

  unactive_buttons()
  {
    for (let i = 0; i < this.buttons.length; i++)
    {
      const button = this.buttons[i];
      button.classList.remove('active');
    }
  }
}

export default new SideMenuManager();
