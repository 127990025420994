module.exports = {
    "title": "THE VALUE",
    "sections": [
        {
            "id": "novices_as_experts",
            "url": "/value/novices_as_experts",
            "title": "Novices as Experts",
            "image": "ai_built_in.png"
        },
        {
            "id": "rapid_turnaround",
            "url": "/value/rapid_turnaround",
            "title": "Rapid Turnaround",
            "image": "rapid_turnaround.png"
        },
        {
            "id": "savings",
            "url": "/value/savings",
            "title": "$$$ Savings",
            "image": "savings.png"
        },
        {
            "id": "easily_trainable",
            "url": "/value/easily_trainable",
            "title": "Easily Trainable",
            "image": "easily_trainable.png"
        },
        {
            "id": "knowledge_management",
            "url": "/value/knowledge_management",
            "title": "Knowledge Management",
            "image": "savings.png"
        },
        {
            "id": "improved_collaboration",
            "url": "/value/improved_collaboration",
            "title": "Improved Collaboration",
            "image": "savings.png"
        },
        {
            "id": "provability_of_results",
            "url": "/value/provability_of_results",
            "title": "Provability of Results",
            "image": "savings.png"
        },
        {
            "id": "risk_mitigation",
            "url": "/value/risk_mitigation",
            "title": "Risk Mitigation",
            "image": "savings.png"
        },
        {
            "id": "value_end",
            "url": "/value/end",
            "title": ""
        }
    ]
}
;