import CarouselSelect from '../common/CarouselSelect';
import HTMLManager from '../common/HTMLManager';

export default class FutureCarouselSelect extends CarouselSelect
{
  constructor(cubes, container, future_view)
  {
    super(cubes, container);

    this.future_view = future_view;
  }

  // @override
  on_element_selected(element_id)
  {
    this.future_view.on_element_selected(element_id);
  }

  // @override
  on_element_selected_clicked(url)
  {
    console.log(url);
    HTMLManager.go_to(url);
  }
}
