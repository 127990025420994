import ApplicationView from './ApplicationView';
import HTMLManager from './HTMLManager';

// import { Configuration } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

export default class SubsectionView extends ApplicationView
{
  constructor(section, title)
  {
    super(section, title);
  }

  start(container, sections_data)
  {
    super.start(container);

    this.sections = [];
    this.sections_ids = [];

    let original_name = this.name;
    for (let i = 0; i < sections_data.sections.length; i++)
    {
      const section = sections_data.sections[i];

      this.sections_ids.push(section.id);
      this.sections.push(document.querySelector((`.section__content.${section.id}`)));
      this.name = `${original_name}/${section.id}`;

      HTMLManager.listen_to_section_changes(this);
    }
    this.name = original_name;

    let current_subsection = window.location.pathname.split('/').pop();

    this.current_subsection = this.sections_ids.includes(current_subsection) ? current_subsection : this.sections_ids[0];
    this.showing_subsection = false;

    // Progress bar stuff
    this.description_container = $(this.container); // .find('.section__subsection-container');
    this.description_container_text = $(this.container).find('.section__subsection-content');

    this.progress_bar = $(this.container).find('.progress-bar__line');
    this.progress_bar_fill = $(this.container).find('.progress-bar__line-fill');

    this.subsections = ResourceContainer.get_resource(this.name.split('/').pop()).sections;

    this.progress_bar_subsections_elements = [];
    this.description_subsections_elements = [];

    for (let i = 0; i < this.subsections.length; i++)
    {
      this.progress_bar_subsections_elements.push($(this.container).find(`.progress-bar__item[data-id="${this.subsections[i].id}"]`));
      this.description_subsections_elements.push($(this.container).find(`.section__content[id="${this.subsections[i].id}"]`));
    }
  }

  update()
  {
    this.update_progress_bar();
  }

  // Clone as SubsectionView
  update_progress_bar()
  {
    const height = (this.description_container_text.height() - this.description_container.height());

    this.description_subsections_elements_tops = [];

    // let current_height = 0;
    for (let i = 0; i < this.description_subsections_elements.length; i++)
    {
      const element = this.description_subsections_elements[i];

      const top = element.position().top < height ? element.position().top : height;

      // this.description_subsections_elements_tops.push(current_height);
      this.description_subsections_elements_tops.push(top);
      // current_height += element.height();
      // current_height += element.position().top;
      // console.log(element.position().top);
    }

    // this.description_subsections_elements_tops.push(height);

    const current_scroll = this.description_container.scrollTop();

    // console.log(this.description_subsections_elements_tops[10], height, current_scroll);

    const partial_bar_height = this.progress_bar.height() / (this.description_subsections_elements_tops.length - 1);

    for (let i = 0; i < this.description_subsections_elements_tops.length - 1; i++)
    {
      let top = this.description_subsections_elements_tops[i];
      let next_top = this.description_subsections_elements_tops[i + 1];

      // Ugly if to fill the scroll bar to the last point
      const last_i = this.description_subsections_elements_tops.length - 2;
      const last_top = this.description_subsections_elements_tops[last_i];
      const scroll_to_condition = last_top + (top - last_top) - 1;

      if (i === last_i &&
          current_scroll > scroll_to_condition &&
          current_scroll >= height - 1)
      // top > current_scroll)
      {
        top = current_scroll - 1;
        next_top = current_scroll;
      }

      // console.log(current_scroll, top, next_top);
      if (current_scroll >= top && current_scroll <= next_top)
      {
        const partial_scroll = this.linear_map(current_scroll, top, next_top, 0, 1);
        // console.log(partial_scroll, partial_bar_height, this.progress_bar.height());
        // console.log(top, current_scroll, next_top);
        this.current_progress = partial_bar_height * (i) + partial_scroll * partial_bar_height;
      }
    }

    // // If sections has only one subsection
    if (this.description_subsections_elements_tops.length === 1)
    {
      let scroll_progress = THREE.Math.clamp(((current_scroll) / height), 0, 1);

      this.current_progress = scroll_progress * this.progress_bar.height();
      // this.progress_bar_fill.css('height', `${scroll_progress * this.progress_bar.height()}px`);
    }

    // // Theres nothing to scroll
    if (height < 0)
    {
      this.current_progress = this.progress_bar.height();
    }

    this.progress_bar_fill.css('height', `${this.current_progress}px`);

    for (let i = 0; i < this.progress_bar_subsections_elements.length; i++)
    {
      // console.log(parseFloat(this.progress_bar_fill.css('height')), parseFloat(this.progress_bar_subsections_elements[i].css('top')));

      if (parseFloat(this.progress_bar_fill.css('height')) + 1 < parseFloat(this.progress_bar_subsections_elements[i].css('top')))
      {
        this.progress_bar_subsections_elements[i].removeClass('active');
      }
      else
      {
        this.progress_bar_subsections_elements[i].addClass('active');
      }

      // const dirty_height = i < this.progress_bar_subsections_elements.length - 1 ? height : height + this.description_container.height();
      // const element_top = (this.description_subsections_elements[i].position().top / dirty_height) * this.progress_bar.height();

      const element_top = i / (this.progress_bar_subsections_elements.length - 1) * this.progress_bar.height();

      this.progress_bar_subsections_elements[i].css('top', `${element_top}px`);
    }
    // }
  }

  // update_progress_bar_old()
  // {
  //   // if (!Configuration.is_mobile)
  //   // {
  //   const height = this.description_container_text.height() - this.description_container.height();
  //   // console.log(this.description_container_text.height(), this.description_container.height());

  //   this.progress_bar_fill.css('height', `${THREE.Math.clamp(((this.description_container.scrollTop()) / height), 0, 1) * (this.progress_bar.height())}px`);

  //   for (let i = 0; i < this.progress_bar_subsections_elements.length; i++)
  //   {
  //     if ((parseFloat(this.progress_bar_fill.css('height')) + 1) < parseFloat(this.progress_bar_subsections_elements[i].css('top')))
  //     {
  //       this.progress_bar_subsections_elements[i].removeClass('active');
  //     }
  //     else
  //     {
  //       this.progress_bar_subsections_elements[i].addClass('active');
  //     }

  //     // const fixed_height = i < this.progress_bar_subsections_elements.length - 1 ? height : this.description_container_text.height();
  //     // const normalized_top = this.description_subsections_elements[i].position().top / fixed_height;
  //     // const top = normalized_top > 1 ? 0.95 : normalized_top;
  //     // this.progress_bar_subsections_elements[i].css('top', `${top * this.progress_bar.height()}px`);

  //     const element_top = i / (this.progress_bar_subsections_elements.length - 1) * this.progress_bar.height();

  //     this.progress_bar_subsections_elements[i].css('top', `${element_top}px`);
  //   }
  //   // }
  // }

  show()
  {
    super.show();
  }

  on_enter()
  {
    if (!this.showing_subsection)
    {
      // this.show_content(this.current_subsection);
    }
    else
    {
      this.showing_subsection = false;
    }
  }

  show_content(section_id)
  {
    this.hide_all_content();
    document.querySelector(`.section__content.${section_id}`).classList.remove('hidden');
    // document.querySelector(`.section__button.${section_id}`).classList.add('active');

    this.current_subsection = section_id;
    this.showing_subsection = true;

    HTMLManager.go_to(`${this.name}/${section_id}`, false);
  }

  hide_all_content()
  {
    for (let i = 0; i < this.sections.length; i++)
    {
      const section = this.sections[i];
      section.classList.add('hidden');
      // document.querySelector(`.section__button.${this.sections_ids[i]}`).classList.remove('active');
    }
  }

  linear_map(value,
    from_range_start_value,
    from_range_end_value,
    to_range_start_value,
    to_range_end_value)
  {
    return ((value - from_range_start_value) / (from_range_end_value - from_range_start_value)) * (to_range_end_value - to_range_start_value) + to_range_start_value;
  }
}
