import { BaseShaderMaterial } from 'ohzi-core';
import { Screen } from 'ohzi-core';

import vert from '/shaders/ss_square/ss_square.vert';
import frag from '/shaders/ss_square/ss_square.frag';

export default class ScreenSpaceSquareMaterial extends BaseShaderMaterial
{
  constructor(color = '#FF0000', size = 10)
  {
    super(vert, frag, {
      _Size: { value: new THREE.Vector2(size, size) },
      _ScreenSize: { value: new THREE.Vector2(1, 1) },
      _PivotPoint: { value: new THREE.Vector2(0, 0) },
      _Color: { value: new THREE.Color(color) }
    });

    this.depthWrite = false;
    this.depthTest = false;
  }

  update()
  {
    this.uniforms._ScreenSize.value.x = Screen.render_width;
    this.uniforms._ScreenSize.value.y = Screen.render_height;
  }

  set_color(col)
  {
    this.uniforms._Color.value.set(col);
  }

  set_size(val)
  {
    this.uniforms._Size.value.set(val * Screen.dpr, val * Screen.dpr);
  }
}
