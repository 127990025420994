import SwipeEventHandler from './SwipeEventHandler';
import ApplicationView from './ApplicationView';

export default class ScrollableRightView extends ApplicationView
{
  constructor(name, id, title, parent_name)
  {
    super(name, title);

    // this.image = document.querySelector(`.${parent_name}__image.${id}`);
    // this.scrollable_container = document.querySelector(`.${parent_name}__container`);
    this.html = $('html');
  }

  start(container)
  {
    super.start(container);
    this.swipe_event = new SwipeEventHandler(this.container);
  }

  //   show()
  //   {
  //     super.show();
  //   }

  //   update()
  //   {
  //     super.update();
  //   }

//   on_resize()
//   {
//     super.on_resize();
//   }
}
