import HTMLManager from './HTMLManager';
import { Time } from 'ohzi-core';

export default class TransitionView
{
  constructor(old_section, new_section)
  {
    this.old_section = old_section;
    this.new_section = new_section;

    this.showing_t = -0.5;
    this.hiding_t = 0;
  }

  on_enter(new_section)
  {
    this.old_section.hide();
    this.new_section.show();
  }

  update()
  {
    this.showing_t = THREE.Math.clamp(this.showing_t + Time.delta_time / this.new_section.show_velocity, -0.5, 1);
    this.hiding_t = THREE.Math.clamp(this.hiding_t + Time.delta_time / this.old_section.hide_velocity, 0, 1);

    this.old_section.update_display(THREE.Math.clamp(this.hiding_t, 0, 1));
    this.new_section.update_display(THREE.Math.clamp(this.showing_t, 0, 1));
    this.new_section.update();

    if (this.showing_t >= 0.9999)
    {
      HTMLManager.set_section(this.new_section);
    }
  }

  on_exit()
  {

  }

  is_transition()
  {
    return true;
  }

  on_resize()
  {

  }

  // hide()
  // {
  //   this.old_section.hide();
  //   this.new_section.hide();
  // }

  // update_display(t)
  // {
  //   this.old_section.update_display(t);
  //   this.new_section.update_display(t);
  // }

  // show()
  // {

  // }
}
