import { Screen } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import Sections from '../../components/Sections';
import TransitionView from './TransitionView';
import State from './State';

class HTMLManager
{
  constructor()
  {
    this.sections = {};
    this.last_screen_height = 0;

    this.html = $('html');
    this.html_el = document.querySelector('html');
    this.body = $('body');
    this.canvas = $('canvas');
    this.background = $('.background');
    this.container = $('.container');

    // this.sections_data = undefined;

    this.previous_section = undefined;
    this.current_section = new State();

    // window.addEventListener('locationchange', this.on_section_change.bind(this, true));
  }

  start()
  {
    // this.sections_data = ResourceContainer.get_resource('section_meta_tags').sections;
    this.on_section_change(true);
  }

  update()
  {
    this.current_section.update();

    if (this.last_screen_height !== Screen.height)
    {
      this.on_resize();
      this.last_screen_height = Screen.height;
    }
  }

  on_resize()
  {
    this.current_section.on_resize();
  }

  on_section_change(is_animated)
  {
    if (Sections.includes(window.location.pathname))
    {
      let section_to_activate = this.sections[window.location.pathname];
      this.previous_section = this.current_section || section_to_activate;

      if (is_animated)
      {
        if (!this.current_section.is_transition() && this.current_section.name !== window.location.pathname)
        {
          this.set_section(new TransitionView(this.previous_section, section_to_activate));
        }
      }
      else
      {
        this.set_section(section_to_activate);
      }

      // this.__update_meta_tags(section_to_activate);
    }
    else
    {
      this.go_to(Sections.sections.HOME);
    }
  }

  set_section(new_section)
  {
    this.current_section.on_exit(this);
    this.current_section = new_section;
    this.current_section.on_enter(this);
  }

  listen_to_section_changes(section)
  {
    this.sections[section.name] = section;
  }

  go_to(section_name, is_animated = true)
  {
    if (Sections.includes(section_name))
    {
      this.__change_browser_url(section_name);
      this.on_section_change(is_animated);
    }
  }

  __change_browser_url(url)
  {
    window.history.pushState('', '', url);
  }

  // __update_meta_tags(subscriber_to_activate)
  // {
  //   for (let i = 0; i < this.sections_data.length; i++)
  //   {
  //     if (this.sections_data[i].url === subscriber_to_activate.name)
  //     {
  //       document.title = this.sections_data[i].title;

  //       let meta_description = document.head.querySelector('meta[name="description"]');
  //       meta_description = meta_description || document.createElement('meta');
  //       meta_description.setAttribute('name', 'description');
  //       meta_description.content = this.sections_data[i].description;
  //       document.head.appendChild(meta_description);

  //       let link = document.querySelector('link[rel=\'canonical\']');
  //       link = link || document.createElement('link');

  //       if (this.sections_data[i].canonical)
  //       {
  //         link.setAttribute('rel', 'canonical');
  //         link.setAttribute('href', 'https://alliedglobalmarketing.com');
  //         document.head.appendChild(link);
  //       }
  //       else
  //       {
  //         link.remove();
  //       }
  //     }
  //   }
  // }
}

export default new HTMLManager();
