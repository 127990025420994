import { Configuration } from 'ohzi-core';

export default class HTMLLabel
{
  constructor(payload)
  {
    // payload: (text, align, class_name, parent, prevent_pointer_events, url)
    this.align = payload.align;

    this.container = document.createElement('a');
    this.container.className = `label ${payload.class_name}`;
    $(this.container).attr('onclick', `app.how_view.go_to(${payload.section_index})`);

    this.p_node = document.createElement('div');
    this.p_node.className = 'label__text';

    this.text_node = document.createTextNode(payload.text);
    this.p_node.appendChild(this.text_node);

    // this.p_node_circle = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    // this.p_node_circle.classList = 'label__circle';
    // this.p_node_circle.style.position = 'absolute';

    // this.p_node_circle_circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

    // if (payload.label_fill)
    // {
    //   this.p_node_circle.setAttribute('width', '10');
    //   this.p_node_circle.setAttribute('height', '10');
    //   this.p_node_circle_circle.setAttribute('cx', '5');
    //   this.p_node_circle_circle.setAttribute('cy', '5');
    //   this.p_node_circle_circle.setAttribute('r', '5');
    //   this.p_node_circle_circle.setAttribute('fill', 'white');
    // }
    // else
    // {
    //   this.p_node_circle.setAttribute('width', '12');
    //   this.p_node_circle.setAttribute('height', '12');
    //   this.p_node_circle_circle.setAttribute('cx', '6');
    //   this.p_node_circle_circle.setAttribute('cy', '6');
    //   this.p_node_circle_circle.setAttribute('r', '4');
    //   this.p_node_circle_circle.setAttribute('stroke-width', '2.5');
    //   this.p_node_circle_circle.setAttribute('fill', 'transparent');
    //   this.p_node_circle_circle.setAttribute('stroke', 'white');
    // }

    // this.p_node_circle.appendChild(this.p_node_circle_circle);

    // this.container.appendChild(this.p_node_circle);
    this.container.appendChild(this.p_node);

    payload.parent.appendChild(this.container);

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    this.current_scale = 1;
    this.current_text_scale = 1;
    this.current_translate = '0,0,0';

    this.current_x = 0;
    this.current_y = 0;

    this.__update_text_transform();

    if (payload.prevent_pointer_events)
    {
      this.p_node.style['pointer-events'] = 'none';
    //   this.p_node_circle.style['pointer-events'] = 'none';
    }
    else
    {
      this.container.addEventListener('mouseenter',
        this.on_label_mouse_enter.bind(this, Configuration.is_mobile ? 1.05 : 1.2));
      this.container.addEventListener('mouseleave',
        this.on_label_mouse_leave.bind(this));
    }
  }

  on_label_mouse_enter(scale)
  {
    this.set_text_opacity(1);
    this.current_text_scale = scale;
    this.__update_text_transform();
    // this.p_node_circle.style.transform = `scale(${scale})`;
  }

  on_label_mouse_leave()
  {
    this.current_text_scale = 1;
    this.__update_text_transform();
    // this.p_node_circle.style.transform = 'scale(1)';
  }

  set_pointer_events(pointer_events)
  {
    this.p_node.style['pointer-events'] = pointer_events;
    // this.p_node_circle.style['pointer-events'] = pointer_events;
  }

  // Update
  set_position(left, top)
  {
    this.current_x = left;
    this.current_y = top;

    this.current_translate = `${left}px, ${top}px, 0`;

    this.__update_transform();
    // this.__update_text_transform();
  }

  set_opacity(value)
  {
    this.container.style.opacity = value;
  }

  set_text_opacity(value)
  {
    this.p_node.style.opacity = value;
  }

  set_scale(value)
  {
    this.current_scale = value;
    this.__update_transform();
  }

  set_text_scale(value)
  {
    this.current_text_scale = value;
    this.__update_text_transform();
  }

  set_text_size(size)
  {
    this.p_node.style.fontSize = `${size}rem`;
  }

  change_text(text, t)
  {
    if (this.t <= 0.5)
    {
      this.text_node.nodeValue = text;
    }

    this.width = this.container.clientWidth;
    this.height = this.container.offsetHeight;

    this.__update_text_transform();
  }

  hide()
  {
    // this.p_node.style.display = 'none';
  }

  show()
  {
    this.container.style.display = 'block';
  }

  __update_text_transform()
  {
    switch (this.align)
    {
    case 'upper-left':
      this.p_node.style.transform = `scale(${this.current_text_scale})`;
      this.p_node.style['transform-origin'] = 'right';
      break;
    case 'lower-left':
      this.p_node.style.transform = `scale(${this.current_text_scale})`;
      this.p_node.style['transform-origin'] = 'right';
      break;
    case 'upper-right':
      this.p_node.style.transform = `scale(${this.current_text_scale})`;
      this.p_node.style['transform-origin'] = 'left';
      break;
    case 'lower-right':
      this.p_node.style.transform = `scale(${this.current_text_scale})`;
      this.p_node.style['transform-origin'] = 'left';
      break;
    }
  }

  __update_transform()
  {
    // this.container.style.transform = `translate3d(${this.current_translate}) scale(${this.current_scale})`;

    const upper_left_x = this.current_x - this.width + (Configuration.is_mobile ? 18 : 13);
    const lower_left_x = this.current_x - this.width + (Configuration.is_mobile ? 18 : 13);
    const upper_right_x = this.current_x - (Configuration.is_mobile ? 18 : 13);
    const lower_right_x = this.current_x - (Configuration.is_mobile ? 18 : 13);

    const upper_left_y = this.current_y - this.height + (Configuration.is_mobile ? 5 : 5);
    const lower_left_y = this.current_y - (Configuration.is_mobile ? 5 : 20);
    const upper_right_y = this.current_y - this.height + (Configuration.is_mobile ? 5 : 5);
    const lower_right_y = this.current_y - (Configuration.is_mobile ? 5 : 5);

    switch (this.align)
    {
    case 'upper-left':
      this.container.style.transform = `translate3d(${upper_left_x}px, ${upper_left_y}px, 0) scale(${this.current_scale})`;
      // this.container.style['transform-origin'] = 'bottom right';
      break;
    case 'lower-left':
      this.container.style.transform = `translate3d(${lower_left_x}px, ${lower_left_y}px, 0) scale(${this.current_scale})`;
      // this.container.style['transform-origin'] = 'top right';
      break;
    case 'upper-right':
      this.container.style.transform = `translate3d(${upper_right_x}px, ${upper_right_y}px, 0) scale(${this.current_scale})`;
      // this.container.style['transform-origin'] = 'bottom left';
      break;
    case 'lower-right':
      this.container.style.transform = `translate3d(${lower_right_x}px, ${lower_right_y}px, 0) scale(${this.current_scale})`;
      // this.container.style['transform-origin'] = 'top left';
      break;
    }
  }
}
