import VisibilityController from './VisibilityController';

class Canvas
{
  constructor()
  {
    this.visibility_controller = new VisibilityController(this);
  }

  start()
  {
    this.container = document.querySelector('.main-canvas');
  }

  show()
  {
    this.visibility_controller.show_view(1, 0.7);
  }

  hide()
  {
    this.visibility_controller.hide_view(0.5);
  }

  update()
  {
    this.visibility_controller.update();
  }

  set_displaying_state(state)
  {
    this.visibility_controller.set_displaying_state(state);
  }
}

export default new Canvas();
