import ScrollableRightView from '../common/ScrollableRightView';
import Canvas from '../common/Canvas';

// import { Screen } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { Time } from 'ohzi-core';

import Sections from '../../components/Sections';

export default class HowSectionView extends ScrollableRightView
{
  constructor(name, id, title, parent_name, shape_controller, index, how_view)
  {
    super(name, id, title, parent_name);

    this.index = index;
    this.shape_controller = shape_controller;

    this.how_view = how_view;

    this.description_container = undefined;
    this.description_container_text = undefined;

    this.progress_bar = undefined;
    this.progress_bar_fill = undefined;
    this.current_progress = 0;
  }

  start(container)
  {
    super.start(container);
    this.square_position = $(this.container).find('.how-section__square-position')[0];
    // this.back_button = this.container.querySelector('.how-section__back-button');

    this.description_container = $(this.container).find('.scrollable-right__description');
    this.description_container_text = $(this.container).find('.scrollable-right__description-text');

    this.progress_bar = $(this.container).find('.progress-bar__line');
    this.progress_bar_fill = $(this.container).find('.progress-bar__line-fill');

    this.sections = ResourceContainer.get_resource('how_sections');

    this.subsections = [];
    for (let i = 0; i < this.sections.length; i++)
    {
      if (this.sections[i].url === this.name)
      {
        this.subsections = this.sections[i].subsections;
      }
    }

    this.progress_bar_subsections_elements = [];
    this.description_subsections_elements = [];

    for (let i = 0; i < this.subsections.length; i++)
    {
      this.progress_bar_subsections_elements.push($(this.container).find(`.progress-bar__item[data-id="${this.subsections[i].id}"]`));
      this.description_subsections_elements.push($(this.container).find(`.how-section__subsection[id="${this.subsections[i].id}"]`));
    }

    // console.log(this.description_subsections_elements);
  }

  update()
  {
    super.update();

    this.how_view.t += Time.delta_time;
    this.how_view.calculate_label_positions();

    this.update_progress_bar();
  }

  show(velocity)
  {
    // TODO: Fix velocity
    super.show(2);

    Configuration.current_how_section_index = this.index;

    Canvas.show();

    // this.__remove_preload_to_videos();
    this.__set_camera_in_square_position();
    // this.container.querySelectorAll('.video--pausable').forEach(vid => vid.load());

    // this.back_button.classList.remove('hidden');

    this.hide_back_button_on_first_section();
    this.hide_next_button_on_last_section();

    const wide_breakpoint = 1600;
    let size = window.innerWidth < wide_breakpoint ? 0.75 : 0.875;
    this.how_view.change_how_labels_size(size);
  }

  // Clone as SubsectionView
  update_progress_bar()
  {
    const height = (this.description_container_text.height() - this.description_container.height());

    this.description_subsections_elements_tops = [];

    // let current_height = 0;
    for (let i = 0; i < this.description_subsections_elements.length; i++)
    {
      const element = this.description_subsections_elements[i];

      const top = element.position().top < height ? element.position().top : height;

      // this.description_subsections_elements_tops.push(current_height);
      this.description_subsections_elements_tops.push(top);
      // current_height += element.height();
      // current_height += element.position().top;
      // console.log(element.position().top);
    }

    // this.description_subsections_elements_tops.push(height);

    const current_scroll = this.description_container.scrollTop();

    // console.log(this.description_subsections_elements_tops[10], height, current_scroll);

    const partial_bar_height = this.progress_bar.height() / (this.description_subsections_elements_tops.length - 1);

    for (let i = 0; i < this.description_subsections_elements_tops.length - 1; i++)
    {
      let top = this.description_subsections_elements_tops[i];
      let next_top = this.description_subsections_elements_tops[i + 1];

      // Ugly if to fill the scroll bar to the last point
      const last_i = this.description_subsections_elements_tops.length - 2;
      const last_top = this.description_subsections_elements_tops[last_i];
      const scroll_to_condition = last_top + (top - last_top) - 1;

      if (i === last_i &&
          current_scroll > scroll_to_condition &&
          current_scroll >= height - 1)
      // top > current_scroll)
      {
        top = current_scroll - 1;
        next_top = current_scroll;
      }

      // console.log(current_scroll, top, next_top);
      if (current_scroll >= top && current_scroll <= next_top)
      {
        const partial_scroll = this.linear_map(current_scroll, top, next_top, 0, 1);
        // console.log(partial_scroll, partial_bar_height, this.progress_bar.height());
        // console.log(top, current_scroll, next_top);
        this.current_progress = partial_bar_height * (i) + partial_scroll * partial_bar_height;
      }
    }

    // // If sections has only one subsection
    if (this.description_subsections_elements_tops.length === 1)
    {
      let scroll_progress = THREE.Math.clamp(((current_scroll) / height), 0, 1);

      this.current_progress = scroll_progress * this.progress_bar.height();
      // this.progress_bar_fill.css('height', `${scroll_progress * this.progress_bar.height()}px`);
    }

    // // Theres nothing to scroll
    if (height < 0)
    {
      this.current_progress = this.progress_bar.height();
    }

    this.progress_bar_fill.css('height', `${this.current_progress}px`);

    for (let i = 0; i < this.progress_bar_subsections_elements.length; i++)
    {
      // console.log(parseFloat(this.progress_bar_fill.css('height')), parseFloat(this.progress_bar_subsections_elements[i].css('top')));

      if (parseFloat(this.progress_bar_fill.css('height')) + 1 < parseFloat(this.progress_bar_subsections_elements[i].css('top')))
      {
        this.progress_bar_subsections_elements[i].removeClass('active');
      }
      else
      {
        this.progress_bar_subsections_elements[i].addClass('active');
      }

      // const dirty_height = i < this.progress_bar_subsections_elements.length - 1 ? height : height + this.description_container.height();
      // const element_top = (this.description_subsections_elements[i].position().top / dirty_height) * this.progress_bar.height();

      const element_top = i / (this.progress_bar_subsections_elements.length - 1) * this.progress_bar.height();

      this.progress_bar_subsections_elements[i].css('top', `${element_top}px`);
    }
    // }
  }

  hide_back_button_on_first_section()
  {
    if (this.name === Sections.sections.HOW_PROJECT_PORTAL)
    {
      const back_button = this.container.querySelector('.how-section__back-button');
      back_button.classList.add('hidden');
    }
  }

  hide_next_button_on_last_section()
  {
    if (this.name === Sections.sections.HOW_OSS_DASHBOARD)
    {
      const next_button = this.container.querySelector('.how-section__next-button');
      next_button.classList.add('hidden');
    }
  }

  toggle_how_labels()
  {
    for (let i = 0; i < this.how_labels.length; i++)
    {
      const label = this.how_labels[i];
      label.classList.remove('hidden');
    }
  }

  on_resize()
  {
    this.__set_camera_in_square_position();
  }

  hide()
  {
    super.hide();
    Canvas.hide();

    // let next_index = THREE.Math.clamp(this.index + 1, 0, 13);
    // this.shape_controller.set_selected_index(next_index);

    // localStorage.setItem('current_section_index', next_index);

    // this.back_button.classList.add('hidden');
  }

  // __remove_preload_to_videos()
  // {
  //   const videos = this.container.querySelectorAll('video');

  //   for (let i = 0; i < videos.length; i++)
  //   {
  //     const video = videos[i];
  //     video.removeAttribute('preload');

  //     if (video.hasAttribute('loop'))
  //     {
  //       video.play();
  //     }
  //   }
  // }

  __set_camera_in_square_position()
  {
    // const rect = this.square_position.getBoundingClientRect();
    // let rect = this.square_position.position();

    // let x = 0;
    // let y = 0;

    // if (!Configuration.is_mobile)
    // {
    //   x = (rect.left / Screen.width) * 2 - 1;
    //   y = (1 - (rect.top / Screen.height)) * 2 - 1;
    // }

    const wide_breakpoint = 1600;
    let x = window.innerWidth < wide_breakpoint ? 0.73 : 0.73;

    x = Configuration.is_mobile ? 0 : x;
    let y = Configuration.is_mobile ? -0.67 : 0.7;

    let zoom = Configuration.is_mobile ? 800 : 800;

    this.shape_controller.set_selected_index(this.index);
    // this.shape_controller.set_camera_index(this.index, new THREE.Vector3(x, y, 0));
    this.shape_controller.set_camera_index(this.index, new THREE.Vector2(x, y), false, zoom);
    this.shape_controller.set_half_size();
  }

  linear_map(value,
    from_range_start_value,
    from_range_end_value,
    to_range_start_value,
    to_range_end_value)
  {
    return ((value - from_range_start_value) / (from_range_end_value - from_range_start_value)) * (to_range_end_value - to_range_start_value) + to_range_start_value;
  }
}
