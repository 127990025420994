import Sections from '../../components/Sections';
import SubsectionView from '../common/SubsectionView';

import sections_data from '../../../assets/data/about.json';

import * as emailjs from '@emailjs/browser';

/* global VirtualSelect */
export default class AboutView extends SubsectionView
{
  constructor(view_data)
  {
    super(Sections.sections.ABOUT, view_data.title);
  }

  start()
  {
    super.start(document.querySelector('.about'), sections_data);

    let select_options = [
      { label: 'Architecture', value: 'Architecture' },
      { label: 'Engineering', value: 'Engineering' },
      { label: 'Consulting', value: 'Consulting' },
      { label: 'Energy Efficiency', value: 'Energy Efficiency' },
      { label: 'Academia', value: 'Academia' },
      { label: 'Technology', value: 'Technology' },
      { label: 'Programmer', value: 'Programmer' },
      { label: 'Government', value: 'Government' },
      { label: 'Contractor', value: 'Contractor' },
      { label: 'Developer', value: 'Developer' },
      { label: 'Owner', value: 'Owner' },
      { label: 'Investor', value: 'Investor' },
      { label: 'Other', value: 'Other' }
    ];

    VirtualSelect.init({
      ele: '.contact__form-select',
      options: select_options,
      multiple: true,
      placeholder: 'Your Industry (check all that apply)'
    });

    emailjs.init('lNDjcbU1NWLWJNpaN');
  }

  send_contact()
  {
    let name = document.querySelector('.contact__form-input--name').value;
    let email = document.querySelector('.contact__form-input--email').value;
    let industries = document.querySelector('.contact__form-input--industries').value;
    let message = document.querySelector('.contact__form-input--message').value;
    let button = document.querySelector('.contact__form-button');

    button.classList.add('disabled');
    button.textContent = 'Sending...';

    if (name && email && message)
    {
      let data = {
        from_name: name,
        from_email: email,
        from_industries: industries.join(', '),
        from_message: message,
        reply_to: email
      };

      console.log(data);

      emailjs.send('service_z3ihn56', 'template_pfsoimb', data)
        .then(() =>
        {
          document.querySelector('.contact__thank-you-message').innerHTML = 'Message sent. Thank you.';
          this.show_thank_you_message();
          button.classList.remove('disabled');
        }).catch((error) =>
        {
          document.querySelector('.contact__thank-you-message').innerHTML = error;
          this.show_thank_you_message();
          button.classList.remove('disabled');
        });

      // let url = 'http://127.0.0.1:3000/contact/send';
      // let url = 'https://server.datadrivendesign-speed.io/contact/send';

      // fetch(url, {
      //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //   mode: 'cors', // no-cors, *cors, same-origin
      //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //   credentials: 'same-origin', // include, *same-origin, omit
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   redirect: 'follow', // manual, *follow, error
      //   body: JSON.stringify(data) // body data type must match "Content-Type" header
      // }).then(() =>
      // {
      //   document.querySelector('.contact__thank-you-message').innerHTML = 'Message sent. Thank you.';
      //   this.show_thank_you_message();
      //   button.classList.remove('disabled');
      // }).catch((error) =>
      // {
      //   document.querySelector('.contact__thank-you-message').innerHTML = error;
      //   this.show_thank_you_message();
      //   button.classList.remove('disabled');
      // });
    }

    return false;
  }

  show_thank_you_message()
  {
    document.querySelector('.contact__form-container').classList.add('hidden');
    document.querySelector('.contact__thank-you-message').classList.remove('hidden');
  }

  show()
  {
    super.show();
  }

  update()
  {
    super.update();
  }

  on_resize()
  {
  }
}
