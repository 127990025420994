import { Components } from 'ohzi-core';
import ScreenSpaceLineMaterial from '/materials/ScreenSpaceLineMaterial';

export default class ScreenSpaceLine extends Components.Line
{
  constructor(color, unfilled_color, thickness)
  {
    super();
    this.material = new ScreenSpaceLineMaterial(color, unfilled_color, thickness);
  }

  update()
  {
    this.material.update();
  }

  set_color(col)
  {
    this.material.set_color(col);
  }

  set_fill(val = 0)
  {
    this.material.set_fill(val);
  }

  set_thickness(thickness)
  {
    this.material.set_thickness(thickness);
  }
}
