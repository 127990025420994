import { Input } from 'ohzi-core';
import { Time } from 'ohzi-core';
import { EasingFunctions } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import HTMLManager from './HTMLManager';
import FontSizeController from '../../components/FontSizeController';

export default class CarouselSelect
{
  constructor(cubes, container)
  {
    this.index = 0;
    this.target = 0;
    this.container = container;
    // borrar
    this.containerEl = this.container[0];
    this.cubes = cubes;

    this.cubes_width = 0;
    this.cubes_height = 0;
    this.container_height = 0;
    // this.container_top = 0;

    this.is_scrolling = false;
    this.is_lerping = false;

    this.init();
    this.tmp_v2 = new THREE.Vector2();

    this.mouse_starting_NDC = new THREE.Vector2();
    this.starting_reference_index = 0;

    this.selected_item = '';

    this.cubes_scales = {};

    this.input_t = 0;
  }

  init()
  {
    this.reference_index = 0;
    this.last_NDC = new THREE.Vector2();

    this.t = 0;
    this.start_index = 0;
    this.end_index = 0;
    this.starting_reference_index = 0;
    this.input_t = 0;
  }

  start()
  {

  }

  update()
  {
    // TODO: Improve this
    this.on_resize();

    // Scrolling with mouse
    if (Input.scrolling_with_mouse)
    {
      if (Math.abs(Input.wheel_delta) > 0.00001)
      {
        this.start_index = this.reference_index;
        let next_element = this.end_index + this.cubes_height * Input.wheel_delta;
        this.end_index = Math.round(next_element / this.cubes_height) * this.cubes_height;
        this.t = 0;
      }
    }

    // Click specific section
    if (Input.left_mouse_button_pressed)
    {
      this.mouse_starting_NDC.copy(Input.NDC);
      this.starting_reference_index = this.reference_index;
      this.start_index  = this.reference_index;
      this.end_index    = this.reference_index;
      this.t = 0;
    }

    if (Input.left_mouse_button_down)
    {
      let speed = Configuration.is_mobile ? this.cubes_height_no_fake * 2 : this.cubes_height * 3;
      let displacement = (Input.NDC.y - this.mouse_starting_NDC.y);
      this.start_index = this.starting_reference_index - displacement * speed;
      this.end_index   = this.starting_reference_index - displacement * speed;
      this.t = 0;
    }

    if (Input.left_mouse_button_released)
    {
      this.start_index = this.reference_index;
      this.end_index = (Math.round(this.reference_index / this.cubes_height) * this.cubes_height);
      this.t = 0;
    }

    // Scrolling with trackpad

    this.is_scrolling = false;
    if (Math.abs(Input.wheel_delta) > 0.00001 && Input.scrolling_with_trackpad)
    {
      let speed = this.cubes_height * 1;

      this.start_index = this.reference_index;
      this.end_index = this.reference_index;

      this.start_index += Input.wheel_delta * speed;
      this.end_index += Input.wheel_delta * speed;

      this.t = 0;
      this.is_scrolling = true;
      this.is_lerping = false;
    }

    if (!this.is_scrolling && !this.is_lerping)
    {
      this.is_lerping = true;
      this.end_index = Math.round(this.reference_index / this.cubes_height) * this.cubes_height;
      this.t = 0;
    }

    if (Input.mouse_clicked() && Input.mouse_is_within_bounds(this.container[0].getBoundingClientRect()))
    {
      let mouse_pos = Input.mouse_pos.y - this.container[0].getBoundingClientRect().top;
      let distance_to_center = this.center - mouse_pos + this.cubes_height / 2;

      this.start_index = this.reference_index;
      let next_element = this.end_index + this.cubes_height * (distance_to_center / this.cubes_height);
      this.end_index = Math.round(next_element / this.cubes_height) * this.cubes_height;

      // Click on selected item
      if (this.start_index === this.end_index && this.can_click())
      {
        this.on_element_selected_clicked(this.selected_item_url);
      }
    }
    // else
    // {
    //   if (this.can_click() && Input.mouse_clicked())
    //   {
    //     this.crystal_raycaster.setFromCamera(Input.NDC, CameraManager.current);

    //     if (this.crystal_raycaster.intersectObject(this.crystal).length > 0)
    //     {
    //       this.on_element_selected_clicked(this.selected_item);
    //     }
    //   }
    // }

    this.reference_index = THREE.Math.lerp(this.start_index, this.end_index, EasingFunctions.ease_out_cubic(this.t));

    this.t += Time.delta_time * 4;
    this.t = THREE.Math.clamp(this.t, 0, 1);

    for (let i = 0; i < this.cubes.length; i++)
    {
      let cube_position_y = this.get_index(i * this.cubes_height + this.reference_index + this.center);

      let minimum_size = 0.7;
      let scale_velocity = 0.35;

      let distance_to_center = THREE.Math.clamp(Math.abs(cube_position_y - this.center) / (this.center), 0, 1);

      let scale = THREE.Math.clamp(Math.pow(1 - distance_to_center, scale_velocity), minimum_size, 1);

      this.cubes_scales[this.cubes[i].dataset.url] = scale;

      let x_offset = -this.cubes_width / 2 * (1 - scale);

      this.cubes[i].style.transform = `translate3d(${x_offset / FontSizeController.html_font_size}rem,${cube_position_y / FontSizeController.html_font_size}rem,0) scale(${scale})`;

      let alpha = Math.pow(THREE.Math.clamp(Math.abs(cube_position_y - this.center) / (this.container_height / 2), 0, 1), 0.5);

      this.cubes[i].style.opacity = 1 - alpha;
      // this.cubes[i].style.opacity = 1 - EasingFunctions.ease_out_sine(alpha);
      // this.cubes[i].visible = distance_to_center < 1;

      if (scale >= 0.9)
      {
        this.selected_item_id = this.cubes[i].dataset.id;
        this.selected_item_url = this.cubes[i].dataset.url;
        this.on_element_selected(this.selected_item_id);
      }
    }

    this.last_NDC.copy(Input.NDC);
    this.input_t += Time.delta_time;
  }

  // virtual
  on_element_selected(element_id)
  {
    console.error('on_element_selected not implemented');
  }

  // virtual
  on_element_selected_clicked(element_id)
  {
    console.error('on_element_selected_clicked not implemented');
  }

  is_mouse_over_container()
  {
    let mouse_over_container = false;
    let cubes_scales_keys = Object.keys(this.cubes_scales);
    let cube_top = this.container_top / cubes_scales_keys.length;

    for (let i = 0; i < cubes_scales_keys.length; i++)
    {
      let cube_width = this.container_width * this.cubes_scales[cubes_scales_keys[i]];

      if (!mouse_over_container)
      {
        if (Input.mouse_pos.x > (this.container_left) &&
            Input.mouse_pos.x < (this.container_left + cube_width) &&
            (Screen.height - Input.mouse_pos.y) > (cube_top * (i + 1)) &&
            (Screen.height - Input.mouse_pos.y) < (cube_top * (i + 1) + this.container_height))
        {
          mouse_over_container = true;
        }
      }
    }

    return mouse_over_container;
  }

  on_resize()
  {
    // let cubes_rect = this.cubes[0].getBoundingClientRect();
    // let container_rect = this.container.getBoundingClientRect();

    // console.log(cubes_rect, container_rect);
    // this.cubes_width = cubes_rect.width;
    // this.cubes_height_no_fake = cubes_rect.height;
    this.cubes_width = this.cubes.width();
    this.cubes_height_no_fake = this.cubes.height();
    this.cubes_height = this.cubes_height_no_fake;

    // this.container_height = container_rect.height;
    // this.container_width  = container_rect.width;
    // this.container_top    = container_rect.top;
    // this.container_left   = container_rect.left;
    this.container_height = this.container.height();
    this.container_width = this.container.width();
    this.container_top = this.containerEl.getBoundingClientRect().top;
    this.container_left = this.containerEl.getBoundingClientRect().left;

    this.center = (this.container_height / 2) - (this.cubes_height_no_fake / 2);
  }

  get_index(i)
  {
    let max_range = this.cubes.length * this.cubes_height;
    return i - Math.floor(i / max_range) * max_range;
  }

  can_click()
  {
    return this.input_t > 0.5;
  }
}
