import { Time } from 'ohzi-core';
import { EasingFunctions } from 'ohzi-core';
import Idle from './Idle';

export default class Hiding
{
  constructor(velocity)
  {
    this.t = 0;
    this.initial_opacity = 0;
    this.target_opacity = 0;
    this.velocity = velocity || 1;
  }

  on_enter(view)
  {
    this.initial_opacity = view.container.style.opacity;
  }

  update(view)
  {
    this.t += Time.delta_time / this.velocity;
    this.easing_function_t = EasingFunctions.ease_in_out_cubic(this.t);
    this.easing_function_t = THREE.Math.clamp(this.easing_function_t, 0, 1);

    view.container.style.opacity = THREE.Math.lerp(this.initial_opacity, this.target_opacity, this.easing_function_t);

    if (this.easing_function_t >= 0.9999)
    {
      view.set_displaying_state(new Idle());
      view.container.classList.add('hidden');
    }
  }

  update_t(view, t)
  {
    view.container.style.opacity = 1 - t;

    if (t >= 0.9999)
    {
      view.set_displaying_state(new Idle());
      view.container.classList.add('hidden');
    }
  }

  on_exit(view)
  {

  }
}
