import CameraViewState from '../states/CameraViewState';

import { Input }  from 'ohzi-core';
import { CameraUtilities } from 'ohzi-core';
import { EasingFunctions } from 'ohzi-core';
import { Time } from 'ohzi-core';

export default class CameraTargetTrackingState extends CameraViewState
{
  constructor(camera_controller)
  {
    super();

    this.camera_controller = camera_controller;
    this.t = 0;

    this.starting_pos = new THREE.Vector3();
    this.starting_zoom = 10;

    this.target_pos = new THREE.Vector3();
    this.target_zoom = 10;
  }

  on_enter(camera_controller)
  {
    this.starting_pos = camera_controller.reference_position.clone();
    this.starting_zoom = camera_controller.reference_zoom;

    this.target_pos = camera_controller.reference_position.clone();
    this.target_zoom = camera_controller.reference_zoom;
  }

  on_exit(camera_controller)
  {

  }

  update(camera_controller)
  {
    this.t += Time.delta_time * 1.5;
    this.t = THREE.Math.clamp(this.t, 0, 1);

    let t = EasingFunctions.ease_in_out_cubic(this.t);

    camera_controller.reference_position.copy(this.starting_pos).lerp(this.target_pos, t);
    camera_controller.reference_zoom = THREE.Math.lerp(this.starting_zoom, this.target_zoom, t);
  }

  set_target(target_ndc, zoom, position_offset, skip = false)
  {
    let v_fov = THREE.Math.degToRad(this.camera_controller.camera.fov / 2);
    let h_fov = (2 * Math.atan(Math.tan(v_fov) * this.camera_controller.camera.aspect)) / 2;

    let horizontal_frustum = target_ndc.x * Math.tan(h_fov) * zoom * -1;
    let vertical_frustum   = target_ndc.y * Math.tan(v_fov) * zoom * 1;

    let target_pos = new THREE.Vector3();

    let x = horizontal_frustum  + position_offset.x;
    let z = vertical_frustum    + position_offset.z;

    this.starting_pos.copy(this.camera_controller.reference_position);
    this.starting_zoom = this.camera_controller.reference_zoom;

    this.target_pos.x = x;
    this.target_pos.z = z;
    this.target_zoom  = zoom;
    this.t = 0;

    if (skip)
    {
      this.t = 1;
      this.camera_controller.reference_position.copy(this.target_pos);
      this.camera_controller.reference_zoom = this.target_zoom;
    }
  }
}
