module.exports = {
    "title": "WHAT IS SPEED",
    "sections": [
        {
            "id": "building_consultant",
            "url": "/what/building_consultant",
            "title": "24/7 Building Consultant",
            "image": "building_consultant.png"
        },
        {
            "id": "parametric_study_workflow",
            "url": "/what/parametric_study_workflow",
            "title": "Parametric Study Workflow",
            "image": "parametric_study_workflow.png"
        },
        {
            "id": "a_balanced_approach",
            "url": "/what/a_balanced_approach",
            "title": "A Balanced Approach",
            "image": "striking_the_balance.png"
        },
        {
            "id": "ecosystem_for_ai",
            "url": "/what/ecosystem_for_ai",
            "title": "Ecosystem for AI",
            "image": "ecosystem_for_ai.png"
        },
        {
            "id": "the_anti_black_box",
            "url": "/what/the_anti_black_box",
            "title": "The Anti-Black Box",
            "image": "the_anti_black_box.png"
        },
        {
            "id": "software_architecture",
            "url": "/what/software_architecture",
            "title": "Software Architecture",
            "image": "software_architecture.png"
        },
        {
            "id": "what_end",
            "url": "/what/end",
            "title": ""
        }
    ]
}
;