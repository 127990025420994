import ApplicationView from '../common/ApplicationView';
import Sections from '../../components/Sections';
import Canvas from '../common/Canvas';

import { Configuration } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { CameraUtilities } from 'ohzi-core';
import { Time } from 'ohzi-core';

import HTMLLabel from '../common/HTMLLabel';
import HTMLManager from '../common/HTMLManager';

export default class HowView extends ApplicationView
{
  constructor(view_data, shape_controller)
  {
    super(Sections.sections.HOW, view_data.title);

    this.sections = [];
    this.sections_labels = [];
    this.last_label_positions = [];
    this.t = 0;

    this.shape_controller = shape_controller;

    Configuration.current_how_section_index = -1;
  }

  start()
  {
    super.start(document.querySelector('.how'));
    this.sections = ResourceContainer.get_resource('how').sections;
    this.container_header_height = $('.container__header').height();

    this.shape_controller.set_camera_index(-1, new THREE.Vector2(0, 0), true);

    // Configuration.current_how_section_index = parseFloat(localStorage.getItem('current_section_index')) || -1;

    this.shape_controller.set_selected_index(0);

    // if (Configuration.current_how_section_index > 0)
    // {
    //   for (let i = 1; i <= Configuration.current_how_section_index; i++)
    //   {
    //     this.shape_controller.set_selected_index(i);
    //   }
    // }

    // if (!Configuration.is_mobile)
    // {
    this.init_section_labels();
    // }

    this.how_labels = document.querySelectorAll('.how__label');
  }

  show()
  {
    super.show();
    Canvas.show();

    // let section_index = Configuration.is_mobile ? Configuration.current_how_section_index : -1;
    const wide_breakpoint = 1600;
    let top = window.innerWidth < wide_breakpoint ? -0.4 : -0.3;
    let zoom_desktop = window.innerWidth < wide_breakpoint ? 480 : 450;

    top = Configuration.is_mobile ? -0.46 : top;
    let zoom = Configuration.is_mobile ? 650 : zoom_desktop;
    let x_offset = Configuration.is_mobile ? 0.07 : -0.2;

    // This moves the camera
    this.shape_controller.set_camera_index(-1, new THREE.Vector2(x_offset, top), false, zoom);

    if (Configuration.is_mobile)
    {
      this.shape_controller.set_half_size();
    }
    else
    {
      this.shape_controller.set_full_size();
    }

    this.__activate_labels();
    this.change_how_labels_size(Configuration.is_mobile ? 0.8 : 1);
  }

  hide()
  {
    super.hide(0);

    Canvas.hide();
  }

  toggle_how_labels()
  {
    for (let i = 0; i < this.how_labels.length; i++)
    {
      const label = this.how_labels[i];
      label.classList.remove('hidden');
    }
  }

  init_section_labels()
  {
    for (let i = 0; i < this.sections.length; i++)
    {
      this.sections_labels.push(new HTMLLabel({
        text: this.sections[i].title,
        align: Configuration.is_mobile ? this.sections[i].label_align_mobile : this.sections[i].label_align,
        class_name: 'how__label hidden',
        parent: document.querySelector('.container__body'),
        url: this.sections[i].url,
        prevent_pointer_events: false,
        section_index: this.sections.indexOf(this.sections[i])
      }));

      this.last_label_positions.push({ x: -1, y: -1 });
    }
  }

  update()
  {
    this.t += Time.delta_time;
    this.calculate_label_positions();
  }

  on_resize()
  {
  }

  go_to(section_index)
  {
    // console.log(this.sections[section_index].url);
    Configuration.current_how_section_index = section_index;

    this.__activate_label(this.sections_labels[section_index].container);

    HTMLManager.go_to(this.sections[section_index].url);

    const parent_container = document.querySelector(`.how-section.${this.sections[Configuration.current_how_section_index].id}`);
    parent_container.querySelector('.scrollable-right__description').scrollTop = 0;
  }

  go_back()
  {
    this.go_to(THREE.Math.clamp(Configuration.current_how_section_index - 1, 0, this.sections.length));
  }

  go_next()
  {
    this.go_to(THREE.Math.clamp(Configuration.current_how_section_index + 1, 0, this.sections.length - 1));
  }

  __activate_labels()
  {
    for (let i = 0; i <= this.shape_controller.max_selected_index; i++)
    {
      this.__activate_label(this.sections_labels[i].container);
    }
  }

  change_how_labels_size(size)
  {
    for (let i = 0; i < this.sections_labels.length; i++)
    {
      const how_label = this.sections_labels[i];
      how_label.set_text_size(size);
    }
  }

  __activate_label(label)
  {
    label.querySelector(':first-child').classList.add('active');
  }

  calculate_label_positions()
  {
    for (let i = 0; i < this.sections.length; i++)
    {
      // if (this.t <= 0.5)
      // {
      this.sections_labels[i].change_text(this.sections[i].title, this.t);
      // }

      // console.log(this.shape_controller.square_array[i].position.x, this.sections_labels[i].current_x);
      // if (this.shape_controller.square_array[i].position.x !== this.sections_labels[i].current_x ||
      //     this.shape_controller.square_array[i].position.y !== this.sections_labels[i].current_y)
      // {
      // this.last_label_positions[i] = {
      //   x: CameraUtilities.world_pos_to_screen(this.shape_controller.square_array[i].position).x,
      //   y: CameraUtilities.world_pos_to_screen(this.shape_controller.square_array[i].position).y - this.container_header_height
      // };
      // console.log(this.last_label_positions[i]);

      this.sections_labels[i].set_position(
        CameraUtilities.world_pos_to_screen(this.shape_controller.square_array[i].position).x,
        CameraUtilities.world_pos_to_screen(this.shape_controller.square_array[i].position).y);
      // }
    }
  }
}
