import Idle from './visibility_states/Idle';
import Showing from './visibility_states/Showing';
import Hiding from './visibility_states/Hiding';

export default class VisibilityController
{
  constructor(view)
  {
    this.view = view;

    this.current_displaying_state = new Idle();
  }

  show_view(target_opacity = 1, velocity = 1)
  {
    this.set_displaying_state(new Showing(target_opacity, velocity));
  }

  hide_view(velocity)
  {
    this.set_displaying_state(new Hiding(velocity));
  }

  set_displaying_state(state)
  {
    // console.log(state.constructor.name);
    this.current_displaying_state.on_exit(this.view);
    this.current_displaying_state = state;
    this.current_displaying_state.on_enter(this.view);
  }

  update()
  {
    this.current_displaying_state.update(this.view);
  }
}
