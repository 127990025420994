export default class Idle
{
  constructor()
  {
  }

  on_enter(view)
  {

  }

  update(view)
  {
  }

  on_exit(view)
  {

  }

  update_t(view, t)
  {}
}
