import { EasingFunctions } from 'ohzi-core';
import { Time } from 'ohzi-core';

import HTMLManager from './HTMLManager';
import VisibilityController from './VisibilityController';

class MenuView
{
  constructor()
  {
    this.visibility_controller = new VisibilityController(this);

    this.slide_t = 0;
    this.slide_toggle = -1;
    this.slide_velocity = 0.5;
  }

  start()
  {
    this.container = document.querySelector('.menu');
    this.right_half = document.querySelector('.menu__right-half');
  }

  show()
  {
    this.visibility_controller.show_view(0.95, 0.7);
    this.slide_toggle = 1;

    document.querySelector('.header__navbar-menu').classList.add('hidden');
  }

  hide()
  {
    this.visibility_controller.hide_view(0.5);
    this.slide_toggle = -1;

    document.querySelector('.header__navbar-menu').classList.remove('hidden');
  }

  set_displaying_state(state)
  {
    this.visibility_controller.set_displaying_state(state);
  }

  go_to(section_name)
  {
    this.hide();
    HTMLManager.go_to(section_name);
  }

  update()
  {
    this.visibility_controller.update();

    this.slide_t += (Time.delta_time / this.slide_velocity) * this.slide_toggle;
    this.slide_t = THREE.Math.clamp(this.slide_t, 0, 1);

    this.right_half.style.transform = `translate3d(${(1 - EasingFunctions.ease_in_out_cubic(this.slide_t)) * 100}%, 0, 0)`;
  }
}

export default new MenuView();
