import { BaseApplication } from 'ohzi-core';
import { CameraManager } from 'ohzi-core';
import { PerspectiveCamera } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import { NormalRender } from 'ohzi-core';
import { Graphics } from 'ohzi-core';
import { SceneManager } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import CameraController from '/js/components/CameraController/CameraController';
import HomeView from './js/html_components/home/HomeView';
import HTMLManager from './js/html_components/common/HTMLManager';
import MenuView from './js/html_components/common/MenuView';
import WhyView from './js/html_components/why/WhyView';
import WhatView from './js/html_components/what/WhatView';
import ValueView from './js/html_components/value/ValueView';
import CaseStudiesView from './js/html_components/casestudies/CaseStudiesView';
import AboutView from './js/html_components/about/AboutView';
import FutureView from './js/html_components/future/FutureView';
// import FullWidthView from './js/html_components/examples/FullWidthView';
// import TwoColumnsView from './js/html_components/examples/TwoColumnsView';
import Sections from './js/components/Sections';
// import WhySectionView from './js/html_components/why/WhySectionView';
// import WhatSectionView from './js/html_components/what/WhatSectionView';
// import ValueSectionView from './js/html_components/value/ValueSectionView';
// import CaseStudiesSectionView from './js/html_components/casestudies/CaseStudiesSectionView';
// import AboutSectionView from './js/html_components/about/AboutSectionView';
// import FutureSectionView from './js/html_components/future/FutureSectionView';
import HowView from './js/html_components/how/HowView';
import Canvas from './js/html_components/common/Canvas';

import MainScene from '/js/components/MainScene';
import FontSizeController from './js/components/FontSizeController';
import HowSectionView from './js/html_components/how/HowSectionView';
import HeaderManager from './js/html_components/common/HeaderManager';

import Modal from './js/html_components/Modal';
import SideMenuManager from './js/html_components/common/SideMenuManager';
// import HowResultsSectionView from './js/html_components/how_results/HowResultsSectionView';

export default class MainApplication extends BaseApplication
{
  constructor(renderer)
  {
    super();
    this.renderer = renderer;

    this.camera_controller = new CameraController();
    this.normal_render_mode = new NormalRender();
  }

  start()
  {
    this.__set_src_from_dataset();

    Graphics.set_state(this.normal_render_mode);

    this.__init_camera();
    this.__init_camera_controller();

    // Meek patch
    Graphics.update();

    this.main_scene = new MainScene(this.camera_controller);
    SceneManager.current.add(this.main_scene);
    let speed_camera_controller = this.main_scene.speed_camera_controller;

    this.config = ResourceContainer.get_resource('config');

    Sections.start();
    HeaderManager.start();
    SideMenuManager.start();
    MenuView.start();
    Canvas.start();

    this.html_manager = HTMLManager;
    this.side_menu_manager = SideMenuManager;
    this.menu_view = MenuView;
    this.modal = Modal;

    let how_view_data = ResourceContainer.get_resource('how');
    this.how_view = new HowView(how_view_data, speed_camera_controller, this.how_sections_views);
    this.how_view.start();

    this.home_view = new HomeView();
    // this.full_width_view = new FullWidthView();
    // this.two_columns_view = new TwoColumnsView();

    let why_view_data = ResourceContainer.get_resource('why');
    this.why_view = new WhyView(why_view_data);
    let what_view_data = ResourceContainer.get_resource('what');
    this.what_view = new WhatView(what_view_data);
    let value_view_data = ResourceContainer.get_resource('value');
    this.value_view = new ValueView(value_view_data);
    let casestudies_view_data = ResourceContainer.get_resource('casestudies');
    this.casestudies_view = new CaseStudiesView(casestudies_view_data);
    let about_view_data = ResourceContainer.get_resource('about');
    this.about_view = new AboutView(about_view_data);
    let future_view_data = ResourceContainer.get_resource('future');
    this.future_view = new FutureView(future_view_data);

    this.home_view.start();
    this.why_view.start();
    this.what_view.start();
    this.value_view.start();
    this.casestudies_view.start();
    this.about_view.start();
    this.future_view.start();
    // this.full_width_view.start();
    // this.two_columns_view.start();

    // Create WhySection Views
    // let why_sections = ResourceContainer.get_resource('why_sections');
    // this.why_sections_views = {};

    // this.__create_sections_from_data(
    //   why_sections,
    //   this.why_sections_views,
    //   'why-section',
    //   WhySectionView,
    //   why_view_data.title
    // );

    // this.why_sections_views_keys = Object.keys(this.why_sections_views);

    // Create HowSection Views
    let how_sections = ResourceContainer.get_resource('how_sections');
    this.how_sections_views = {};

    this.__create_sections_from_data(
      how_sections,
      this.how_sections_views,
      'how-section',
      HowSectionView,
      how_view_data.title,
      speed_camera_controller
    );

    // // Create HowResultSections Views
    // let how_result_sections = ResourceContainer.get_resource('how_results_sections');
    // this.how_result_sections_views = {};

    // this.__create_sections_from_data(
    //   how_result_sections,
    //   this.how_result_sections_views,
    //   'how-results-section',
    //   HowResultsSectionView,
    //   how_results_view_data.title
    // );

    // Create WhatSection Views
    // let what_sections = ResourceContainer.get_resource('what_sections');
    // this.what_sections_views = {};

    // this.__create_sections_from_data(
    //   what_sections,
    //   this.what_sections_views,
    //   'what-section',
    //   WhatSectionView,
    //   what_view_data.title
    // );

    // console.log(this.what_sections_views);

    // Create ValueSection Views
    // let value_sections = ResourceContainer.get_resource('value_sections');
    // this.value_sections_views = {};

    // this.__create_sections_from_data(
    //   value_sections,
    //   this.value_sections_views,
    //   'value-section',
    //   ValueSectionView,
    //   value_view_data.title
    // );

    // Create CaseStudiesSection Views
    // let casestudies_sections = ResourceContainer.get_resource('casestudies_sections');
    // this.casestudies_sections_views = {};

    // this.__create_sections_from_data(
    //   casestudies_sections,
    //   this.casestudies_sections_views,
    //   'casestudies-section',
    //   CaseStudiesSectionView,
    //   casestudies_view_data.title
    // );

    // // Create About Views
    // let about_sections = ResourceContainer.get_resource('about_sections');
    // this.about_sections_views = {};

    // this.__create_sections_from_data(
    //   about_sections,
    //   this.about_sections_views,
    //   'about-section',
    //   AboutSectionView,
    //   about_view_data.title
    // );

    // // Create Future Views
    // let future_sections = ResourceContainer.get_resource('future_sections');
    // this.future_sections_views = {};

    // this.__create_sections_from_data(
    //   future_sections,
    //   this.future_sections_views,
    //   'future-section',
    //   FutureSectionView,
    //   future_view_data.title
    // );

    HTMLManager.start();

    // this.__bind_click_event_to_all_videos();
  }

  update()
  {
    this.camera_controller.update();
    HTMLManager.update();

    MenuView.update();
    Canvas.update();
    this.main_scene.update();
  }

  on_resize()
  {
    // Graphics.on_resize();
    // console.log(Screen.width, Screen.height);
    FontSizeController.on_resize(HTMLManager.html_el, Configuration.is_mobile);
    HTMLManager.on_resize();
  }

  post_start()
  {
    this.on_resize();
  }

  __set_src_from_dataset()
  {
    const videos = document.querySelectorAll('video');

    for (let i = 0; i < videos.length; i++)
    {
      const video = videos[i];

      if (video.dataset.src)
      {
        // video.src = video.dataset.src;
        // delete video.dataset.src;
      }
    }

    const images = document.querySelectorAll('img');

    for (let i = 0; i < images.length; i++)
    {
      const image = images[i];

      if (image.dataset.src)
      {
        image.src = image.dataset.src;
        // delete image.dataset.src;
      }
    }
  }

  // __bind_click_event_to_all_videos()
  // {
  //   let videos = document.querySelectorAll('video');

  //   for (let i = 0; i < videos.length; i++)
  //   {
  //     const video = videos[i];

  //     video.addEventListener('click', this.__on_video_click.bind(this));
  //   }
  // }

  __on_video_click(event)
  {
    event.target.requestFullscreen();
    // console.log(event.target);
  }

  __create_sections_from_data(objs, views, parent_section_id, View, custom_title, extra_element)
  {
    for (let i = 0; i < objs.length; i++)
    {
      views[objs[i].url] = new View(objs[i].url, objs[i].id, custom_title || objs[i].title, parent_section_id, extra_element, i, this.how_view);

      views[objs[i].url].start(document.querySelector(`.${parent_section_id}.${objs[i].id}`));
    }
  }

  __init_camera()
  {
    let camera = new PerspectiveCamera(60, Screen.aspect_ratio, 0.1, 1000);
    camera.updateProjectionMatrix();
    camera.position.z = 10;

    camera.clear_color.copy(new THREE.Color('#FFFFFF'));
    camera.clear_alpha = 1;
    CameraManager.current = camera;
  }

  __init_camera_controller()
  {
    this.camera_controller.set_camera(CameraManager.current);
    // this.camera_controller.set_idle();
    this.camera_controller.set_debug_mode();

    this.camera_controller.min_zoom = 1;
    this.camera_controller.max_zoom = 1000;
    this.camera_controller.reference_zoom = 15;
    this.camera_controller.reference_position.set(0, 0, 0);
    this.camera_controller.set_rotation(90, 0);
  }
}
