module.exports = {
    "title": "ABOUT US",
    "sections": [
        {
            "id": "vision",
            "url": "/about/vision",
            "title": "Vision",
            "image": "vision.png"
        },
        {
            "id": "team",
            "url": "/about/team",
            "title": "Our Team",
            "image": "team.png"
        },
        {
            "id": "contact",
            "url": "/about/contact",
            "title": "Contact US",
            "image": "contact.png"
        },
        {
            "id": "about_end",
            "url": "/about/end",
            "title": ""
        }
    ]
}
;