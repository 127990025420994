module.exports = {
    "title": "PROJECTS",
    "sections": [
        {
            "id": "bellevue_college_transdisciplinary",
            "url": "/casestudies/bellevue_college_transdisciplinary",
            "title": "Higher Education",
            "image": "1",
            "building_type":"Educational",
            "project_size": "~75,000 sf",
            "location": "Bellevue, WA"
        },
        {
            "id": "uch",
            "url": "/casestudies/uch_hasting",
            "title": "Housing",
            "image": "2",
            "building_type":"Mid-Rise Apartment",
            "project_size": "~360,000 sf",
            "location": "San Francisco, CA"
        },
        {
            "id": "westdale_palomar",
            "url": "/casestudies/westdale_palomar",
            "title": "Housing",
            "image": "3",
            "building_type":"High-Rise Apartment",
            "project_size": "~206,000 sf",
            "location": "Dallas, TX"
        },
        {
            "id": "ucsd_pepper_canyon",
            "url": "/casestudies/ucsd_pepper_canyon",
            "title": "Housing",
            "image": "4",
            "building_type":"High-Rise Residential",
            "project_size": "~841,000 sf",
            "location": "La Jolla, CA"
        },
        {
            "id": "health_care_1",
            "url": "/casestudies/health_care_1",
            "title": "Healthcare",
            "image": "5",
            "building_type":"Hospital",
            "project_size": "~1,500,000 sf",
            "location": "Charlottesville, VA"
        },
        {
            "id": "medical_properties_trust",
            "url": "/casestudies/medical_properties_trust",
            "title": "Corporate and Commercial",
            "image": "6",
            "building_type":"Large Office",
            "project_size": "~160,000 sf",
            "location": "Vestavia Hills, AL"
        },
        {
            "id": "school_3",
            "url": "/casestudies/school_3",
            "title": "K-12 Education",
            "image": "7",
            "building_type":"Primary School",
            "project_size": "~250,000 sf",
            "location": "Hopkins, MN"
        },
        {
            "id": "cover_my_meds",
            "url": "/casestudies/cover_my_meds",
            "title": "Corporate and Commercial",
            "image": "8",
            "building_type":"Office",
            "project_size": "~375,000 sf",
            "location": "Cleveland, Ohio"
        },
        {
            "id": "waltham_woods",
            "url": "/casestudies/waltham_woods",
            "title": "Corporate and Commercial",
            "image": "9",
            "building_type":"Office",
            "project_size": "~24,000 sf",
            "location": "Waltham, MA"
        },
        {
            "id": "towson_health_professions",
            "url": "/casestudies/towson_health_professions",
            "title": "Higher Education",
            "image": "10",
            "building_type":"Higher Education",
            "project_size": "~240,000 sf",
            "location": "Towson, MD"
        },
        {
            "id": "lab_1",
            "url": "/casestudies/lab_1",
            "title": "Science and Technology",
            "image": "11",
            "building_type":"Lab + Multifamily",
            "project_size": "~600,000 sf",
            "location": "Bloomington, MN"
        },
        {
            "id": "epic_building_2",
            "url": "/casestudies/epic_building_2",
            "title": "Corporate and Commercial",
            "image": "12",
            "building_type":"Office",
            "project_size": "~750,000 sf",
            "location": "Dallas, TX"
        },
        {
            "id": "58_hampden_st",
            "url": "/casestudies/58_hampden_st",
            "title": "Science and Technology",
            "image": "13",
            "building_type":"Office & Lab",
            "project_size": "~568,000 sf",
            "location": "Boston, MA"
        },
        {
            "id": "smu_oakland_campus",
            "url": "/casestudies/smu_oakland_campus",
            "title": "Higher Education",
            "image": "14",
            "building_type":"Higher Education",
            "project_size": "~250,000 sf",
            "location": "Oakland, CA"
        },
        {
            "id": "michigan_tech_university",
            "url": "/casestudies/michigan_tech_university",
            "title": "Higher Education",
            "image": "15",
            "building_type":"Higher Education",
            "project_size": "~132,000 sf",
            "location": "Houghton, MI"
        },
        {
            "id": "bellevue_wa",
            "url": "/casestudies/bellevue_wa",
            "title": "Higher Education",
            "image": "16",
            "building_type":"Higher Education",
            "project_size": "~102,000 sf",
            "location": "Bowie, MD"
        },
        {
            "id": "st_marks_hospital",
            "url": "/casestudies/st_marks_hospital",
            "title": "Healthcare",
            "image": "17",
            "building_type":"Healthcare Facility",
            "project_size": "~26,000 sf",
            "location": "Millcreek, UT"
        },
        {
            "id": "seymour_hotel",
            "url": "/casestudies/seymour_hotel",
            "title": "Hospitality",
            "image": "18",
            "building_type":"Large Hotel",
            "project_size": "~245,000 sf",
            "location": "Vancouver, CA"
        },
        {
            "id": "housing_1",
            "url": "/casestudies/housing_1",
            "title": "Housing",
            "image": "19",
            "building_type":"Mid-Rise Apartment",
            "project_size": "~18,000 sf",
            "location": "Shoreline, WA"
        },
        {
            "id": "school_1",
            "url": "/casestudies/school_1",
            "title": "K-12 Education",
            "image": "20",
            "building_type":"Elementary/Middle School",
            "project_size": "~35,000 sf",
            "location": "Columbia, SC"
        },
        {
            "id": "housing_3",
            "url": "/casestudies/housing_3",
            "title": "Housing",
            "image": "21",
            "building_type":"Mid-Rise Apartment",
            "project_size": "~18,000 sf",
            "location": "Dallas, TX"
        },
        {
            "id": "housing_2",
            "url": "/casestudies/housing_2",
            "title": "Housing",
            "image": "22",
            "building_type":"High-Rise Apartment",
            "project_size": "~243,000 sf",
            "location": "Chicago, IL"
        },
        {
            "id": "san_marcos_school",
            "url": "/casestudies/san_marcos_school",
            "title": "K-12 Education",
            "image": "23",
            "building_type":"Elementary School",
            "project_size": "~126,000 sf",
            "location": "San Marcos, TX"
        },
        {
            "id": "school_2",
            "url": "/casestudies/school_2",
            "title": "K-12 Education",
            "image": "24",
            "building_type":"Elementary School",
            "project_size": "~132,000 sf",
            "location": "Greenville, SC"
        },
        {
            "id": "renown_medical_center",
            "url": "/casestudies/renown_medical_center",
            "title": "Healthcare",
            "image": "25",
            "building_type":"Healthcare Facility",
            "project_size": "~148,000 sf",
            "location": "Reno, NV"
        }
    ]
}
;