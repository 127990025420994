import HTMLManager from './HTMLManager';
import State from './State';
import Idle from './visibility_states/Idle';
import Showing from './visibility_states/Showing';
import Hiding from './visibility_states/Hiding';
import HeaderManager from './HeaderManager';

import { Configuration } from 'ohzi-core';
import Modal from '../Modal';

export default class ApplicationView extends State
{
  constructor(name, title)
  {
    super();

    this.name = name;
    this.title = title;
    this.container = undefined;
    this.current_displaying_state = new Idle();

    this.show_velocity = 0.5;
    this.hide_velocity = 0.2;
  }

  start(container)
  {
    this.container = container;

    HTMLManager.listen_to_section_changes(this);

    // TODO: Improve this
    this.how_labels = document.querySelectorAll('.how__label');
  }

  on_resize()
  {
  }

  show(velocity = 1)
  {
    this.set_displaying_state(new Showing(1, velocity));
    HeaderManager.change_title(this.title);

    this.container.querySelectorAll('.how-section__gif').forEach(vid => this.play_video(vid));

    this.toggle_how_labels();

    this.container.scrollTop = 0;
  }

  hide(velocity = 1)
  {
    this.set_displaying_state(new Hiding(velocity));

    this.container.querySelectorAll('.how-section__gif').forEach(vid => this.pause_video(vid));
    document.querySelectorAll('.video--pausable').forEach(vid => vid.pause());
  }

  toggle_how_labels()
  {
    for (let i = 0; i < this.how_labels.length; i++)
    {
      const label = this.how_labels[i];
      label.classList.add('hidden');
    }
  }

  update_display(t)
  {
    this.current_displaying_state.update_t(this, t);
  }

  set_displaying_state(state)
  {
    // console.log(state.constructor.name);
    this.current_displaying_state.on_exit(this);
    this.current_displaying_state = state;
    this.current_displaying_state.on_enter(this);
  }

  pause_video(video)
  {
    video.pause();

    video.src = '';
  }

  play_video(video)
  {
    let webm = false;
    if (video.canPlayType)
    {
      webm = video.canPlayType('video/webm;codecs="vp9,opus"') !== '';
    }

    const extension = webm ? 'webm' : 'mp4';

    video.src = `${video.dataset.src}.${extension}`;

    video.play();
  }

  show_gif(how_section_id, video_id, video_element)
  {
    if (Configuration.is_mobile)
    {
      if (Configuration.is_ios)
      {
        video_element.removeAttribute('playsinline');
        video_element.pause();
        video_element.play();

        video_element.addEventListener('webkitendfullscreen', () =>
        {
          setTimeout(() =>
          {
            video_element.setAttribute('playsinline', '');
            video_element.pause();
            video_element.play();
            video_element.removeAttribute('controls');
          }, 1000);
        });
      }
      else
      {
        video_element.requestFullscreen();
      }
    }
    else
    {
      let webm = false;
      if (video_element.canPlayType)
      {
        webm = video_element.canPlayType('video/webm;codecs="vp9,opus"') !== '';
      }

      const extension = webm ? 'webm' : 'mp4';

      let body = `<div class="modal__video"><video src="/videos${this.name}/${how_section_id}/${video_id}.${extension}" muted loop autoplay playsinline></div>`;

      Modal.show({ body, custom_classes: 'is-70' });
    }
  }
}
