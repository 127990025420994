module.exports = {
    "title": "WHY SPEED",
    "sections": [
        {
            "id": "poor_early_design_process",
            "url": "/why/poor_early_design_process",
            "title": "Poor Early Design Process",
            "image": "poor_early_design_process.png"
        },
        {
            "id":"lack_of_true_design_guidance",
            "url": "/why/lack_of_true_design_guidance",
            "title": "Lack Of True Design Guidance",
            "image": "poor_early_design_tools.png"
        },
        {
            "id": "inadequate_early_design_tools",
            "url": "/why/inadequate_early_design_tools",
            "title": "Inadequate Early Design Tools",
            "image": "poor_early_design_tools.png"
        },
        {
            "id": "carbon_reduction_too_slow",
            "url": "/why/carbon_reduction_too_slow",
            "title": "Carbon Reduction Too Slow",
            "image": "carbon_reduction_too_slow.png"
        },
        {
            "id": "why_end",
            "url": "/why/end",
            "title": ""
        }
    ]
}
;