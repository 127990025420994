class Modal
{
  constructor()
  {
    // this.container = undefined;
    this.current_custom_classes = '';
  }

  start()
  {
    // this.container = document.querySelector('.modal');

    // this.title = document.querySelector('.modal__title');
    // this.body = document.querySelector('.modal__body');
  }

  show({ title, body, custom_classes = '' })
  {
    document.querySelector('.modal__title').textContent = title;
    document.querySelector('.modal__body').innerHTML = body;

    if (custom_classes)
    {
      document.querySelector('.modal__container').classList.add(custom_classes);
    }

    document.querySelector('.modal').classList.remove('hidden');

    this.current_custom_classes = custom_classes;
  }

  hide()
  {
    if (this.current_custom_classes)
    {
      document.querySelector('.modal__container').classList.remove(this.current_custom_classes);
    }

    document.querySelector('.modal').classList.add('hidden');
  }
}

export default new Modal();
