export default class State
{
  constructor()
  {
    this.show_velocity = 0.01;
    this.hide_velocity = 0.01;
  }

  on_enter()
  {
  }

  update()
  {
  }

  on_exit()
  {
  }

  show()
  {
  }

  hide()
  {
  }

  update_display()
  {
  }

  is_transition()
  {
    return false;
  }

  on_resize()
  {}
}
