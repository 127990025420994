import { Input } from 'ohzi-core';

/* global ZingTouch */
export default class SwipeEventHandler
{
  constructor(container)
  {
    let region = new ZingTouch.Region(container, false, false);
    let one_finger_swipe = new ZingTouch.Swipe({
      numInputs: 1,
      maxRestTime: 100,
      escapeVelocity: 0.25
    });

    region.register('one_finger_swipe', one_finger_swipe);
    region.bind(container, 'one_finger_swipe', (event) =>
    {
      if (event.detail.data.length > 0)
      {
        if (event.detail.data[0].currentDirection > 145 &&
            event.detail.data[0].currentDirection < 215)
        {
          Input.swiped_left = true;
        }

        if (event.detail.data[0].currentDirection >= 0 &&
            (event.detail.data[0].currentDirection < 35 ||
            event.detail.data[0].currentDirection > 325))
        {
          Input.swiped_right = true;
        }
      }
    });
  }
}
