import ApplicationView from '../common/ApplicationView';
import Sections from '../../components/Sections';

/* global lottie */
export default class HomeView extends ApplicationView
{
  constructor()
  {
    super(Sections.sections.HOME);
  }

  start()
  {
    super.start(document.querySelector('.home'));

    this.arrows_container = document.querySelector('.home__image-arrows');
    this.video_container = document.querySelector('.home__image-video');
    this.video_play_icon = document.querySelector('.home__image-play-button');

    this.video_play_icon.addEventListener('click', this.__on_video_click.bind(this));
    this.video_container.addEventListener('click', this.__on_video_click.bind(this));
    this.video = document.querySelector('.home__image-video-video');
    // this.video.load();

    this.animation_loaded = false;
  }

  show()
  {
    super.show();

    if (!this.animation_loaded)
    {
      // lottie.loadAnimation({
      //   container: this.arrows_container,
      //   renderer: 'svg',
      //   loop: true,
      //   autoplay: true,
      //   path: 'animations/home/arrows.json' // the path to the animation json
      // });

      this.animation_loaded = true;
    }

    this.video.play();
  }

  hide()
  {
    super.hide();

    this.video.pause();
    this.video.muted = true;
    // this.__show_play_icons();
  }

  __on_video_click()
  {
    // this.video.play();
    // this.video.requestFullscreen();
    // this.__hide_play_icons();
  }

  __hide_play_icons()
  {
    this.video_play_icon.style.opacity = 0;
    this.video_play_icon.style.pointerEvents = 'none';

    this.arrows_container.style.opacity = 0;
    this.arrows_container.style.pointerEvents = 'none';
  }

  __show_play_icons()
  {
    this.video_play_icon.style.opacity = 1;
    this.video_play_icon.style.pointerEvents = 'auto';

    this.arrows_container.style.opacity = 1;
    this.arrows_container.style.pointerEvents = 'auto';
  }
}
