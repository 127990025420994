import { Configuration } from 'ohzi-core';

export default class SpeedShapeCameraController
{
  constructor({
    camera_tracker,
    square_array,
    lines_array,
    zoom_array,
    default_size,
    selected_size,
    zoom_far_out = 100,
    default_thickness
  })
  {
    this.camera_tracker = camera_tracker;
    this.square_array = square_array;
    this.lines_array = lines_array;

    this.zoom_array = zoom_array;

    this.default_size   = default_size;
    this.default_thickness   = default_thickness;
    this.selected_size  = selected_size;
    this.zoom_far_out = zoom_far_out;

    this.max_selected_index = -1;

    this.dark_gray_color = '#707070';
    this.aqua_color = '#3F91A5';
    this.green_color = '#50ABAE';
    this.gray_color = '#BBBBBB';

    this.__reset_state();
  }

  set_selected_index(i)
  {
    // this.__reset_squares();
    this.__reset_state();

    this.max_selected_index = i;

    for (let j = 0; j < i; j++)
    {
      this.lines_array[j].set_color(this.aqua_color);
      this.lines_array[j].set_fill(1);
    }

    for (let j = 0; j < this.max_selected_index + 1; j++)
    {
      this.square_array[j].set_color(this.dark_gray_color);
    }

    this.square_array[i].set_color(this.green_color);
    this.square_array[i].set_size(this.selected_size);

    // if (this.max_selected_index < i)
    // {
    this.max_selected_index = i;
    // }
  }

  set_camera_index(i, ndc = new THREE.Vector2(), skip = false, zoom = this.zoom_far_out)
  {
    if (i === -1)
    {
      this.camera_tracker.set_target(ndc, zoom, new THREE.Vector3(), skip);
    }
    else
    {
      if (Configuration.is_mobile)
      {
        // this.camera_tracker.set_target(ndc, zoom, this.square_array[i].position, skip);
        this.camera_tracker.set_target(ndc, zoom, new THREE.Vector3(), skip);
      }
      else
      {
        // this.zoom_array[i]
        this.camera_tracker.set_target(ndc, zoom, new THREE.Vector3(), skip);
      }
    }
  }

  set_full_size()
  {
    this.__set_squares_size(this.default_size);
    this.__set_lines_thickness(this.default_thickness);
  }

  set_half_size()
  {
    this.__set_squares_size(this.default_size / 2);
    this.__set_lines_thickness(this.default_thickness / 2);
  }

  __set_squares_size(size)
  {
    for (let i = 0; i < this.square_array.length; i++)
    {
      this.square_array[i].set_size(size);
    }
  }

  __set_lines_thickness(thickness)
  {
    for (let i = 0; i < this.lines_array.length; i++)
    {
      this.lines_array[i].set_thickness(thickness);
    }
  }

  __reset_state()
  {
    this.__reset_squares();
    this.__reset_lines();
  }

  __reset_squares()
  {
    for (let i = 0; i < this.square_array.length; i++)
    {
      this.square_array[i].set_size(this.default_size);
      this.square_array[i].set_color(this.gray_color);
    }
  }

  __reset_lines()
  {
    for (let i = 0; i < this.lines_array.length; i++)
    {
      this.lines_array[i].set_color('#D9D9D9');
      this.lines_array[i].set_fill(-1);
    }
  }
}
