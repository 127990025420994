import ApplicationView from '../common/ApplicationView';
import Sections from '../../components/Sections';
import FutureCarouselSelect from './FutureCarouselSelect';

export default class FutureView extends ApplicationView
{
  constructor(view_data)
  {
    super(Sections.sections.FUTURE, view_data.title);

    this.carousel_container = $('.future__carousel-select');
    this.carousel_cubes = $('.future__carousel-select-box');
    this.carousel_select = undefined;

    this.carousel_select = new FutureCarouselSelect(this.carousel_cubes,
      this.carousel_container,
      this);

    this.future_images = $('.future__image');
  }

  start()
  {
    super.start(document.querySelector('.future'));
  }

  show()
  {
    super.show();

    this.carousel_select.init();
    this.carousel_select.on_resize();
  }

  update()
  {
    this.carousel_select.update();
  }

  on_resize()
  {
    this.carousel_select.on_resize();
  }

  on_element_selected(element_id)
  {
    if ($(`.future__image.${element_id}`).hasClass('hidden'))
    {
      this.future_images.addClass('hidden');
      $(`.future__image.${element_id}`).removeClass('hidden');
    }
  }
}
