import { ResourceContainer } from 'ohzi-core';

class Sections
{
  constructor()
  {
    this.sections = {
      LOADER: '/loader',
      HOME: '/',
      MENU: '/menu',
      WHY: '/why',
      WHAT: '/what',
      HOW: '/how',
      HOW_RESULTS: '/how/results',
      VALUE: '/value',
      CASE_STUDIES: '/projects',
      ABOUT: '/about',
      FUTURE: '/future',
      FULL_WIDTH: '/full_width',
      TWO_COLUMNS: '/two_columns'
    };

    this.scrollable_sections = [
      // this.sections.WHAT,
      this.sections.HOME,
      this.sections.FULL_WIDTH
      // this.sections.TWO_COLUMNS
    ];
  }

  start()
  {
    let why = ResourceContainer.get_resource('why').sections;
    this.__create_sections_from_data(why, 'WHY', true);

    let what = ResourceContainer.get_resource('what').sections;
    this.__create_sections_from_data(what, 'WHAT', true);

    let how = ResourceContainer.get_resource('how').sections;
    this.__create_sections_from_data(how, 'HOW', true);

    let how_results = ResourceContainer.get_resource('how_results').sections;
    this.__create_sections_from_data(how_results, 'HOW_RESULTS', true);

    let value = ResourceContainer.get_resource('value').sections;
    this.__create_sections_from_data(value, 'VALUE', true);

    let casestudies = ResourceContainer.get_resource('casestudies').sections;
    this.__create_sections_from_data(casestudies, 'CASE_STUDIES', true);

    let about = ResourceContainer.get_resource('about').sections;
    this.__create_sections_from_data(about, 'ABOUT', true);

    let future = ResourceContainer.get_resource('future').sections;
    this.__create_sections_from_data(future, 'FUTURE', true);

    this.sections_array = Object.values(this.sections);
  }

  includes(section)
  {
    return this.sections_array.includes(section);
  }

  __create_sections_from_data(objs, parent, is_scrollable)
  {
    for (let i = 0; i < objs.length; i++)
    {
      this.sections[`${parent}_${objs[i].id.toUpperCase()}`] = objs[i].url;

      if (is_scrollable)
      {
        this.scrollable_sections.push(objs[i].url);
      }
    }
  }
}

export default new Sections();
