import Sections from '../../components/Sections';

import sections_data from '../../../assets/data/casestudies.json';
import ApplicationView from '../common/ApplicationView';
import Modal from '../Modal';

import { Configuration } from 'ohzi-core';

export default class CaseStudiesView extends ApplicationView
{
  constructor(view_data)
  {
    super(Sections.sections.CASE_STUDIES, view_data.title);
  }

  start()
  {
    super.start(document.querySelector('.casestudies'));

    this.sections = sections_data.sections;
  }

  show()
  {
    super.show();
  }

  update()
  {
  }

  on_resize()
  {
  }

  show_case(case_title, case_image)
  {
    let title = Configuration.is_mobile ? '' : case_title;
    // let path = `images/casestudies/${Configuration.is_mobile ? 'mobile' : 'originals'}/${case_image}`;
    let body = `<div class="modal__images">
                  <div class="modal__image"><img src="images/casestudies/big/${case_image}_1.jpg"></div>
                  <div class="modal__image"><img src="images/casestudies/big/${case_image}_2.jpg"></div>
                </div>`;

    Modal.show({ title, body });
  }
}
