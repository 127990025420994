import Sections from '../../components/Sections';
import SubsectionView from '../common/SubsectionView';

import sections_data from '../../../assets/data/what.json';

export default class WhatView extends SubsectionView
{
  constructor(view_data)
  {
    super(Sections.sections.WHAT, view_data.title);
  }

  start()
  {
    super.start(document.querySelector('.what'), sections_data);
  }

  show()
  {
    super.show();
  }

  update()
  {
    super.update();
  }

  on_resize()
  {
  }
}
