import ScreenSpaceSquareMaterial from '/materials/ScreenSpaceSquareMaterial';

export default class ScreenSpaceSquare extends THREE.Mesh
{
  constructor(color, size)
  {
    super(new THREE.PlaneGeometry(1, 1), new ScreenSpaceSquareMaterial(color, size));
  }

  update()
  {
    this.material.update();
  }

  set_size(val)
  {
    this.material.set_size(val);
  }

  set_color(col)
  {
    this.material.set_color(col);
  }
}
